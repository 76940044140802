<template>
  <v-container fluid class="px-8">
    <v-row align="center">
      <v-col>
        <h3>Keyword Rotator</h3>
<!--        <v-alert-->
<!--            dense-->
<!--            text-->
<!--            outlined-->
<!--            icon="mdi-alert-circle-outline"-->
<!--            type="info"-->
<!--            class="mt-4 mb-n2"-->
<!--        >Temporary redirect time is extended due to technical issues. We working hard to solve it asap</v-alert>-->
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-actions class="pa-5">
            <v-row class="custom--gutters" >
              <v-col>
                <v-text-field
                    v-model.trim="filter.search"
                    label="Search..."
                    solo
                    outlined
                    dense
                    flat
                    hide-details
                    class="font-size-15"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" >
                <v-select
                  :items="Object.values(rotatorTypesForFilter)"
                  label="Campaign Type"
                  v-model="filter.type"
                  solo
                  outlined
                  dense
                  flat
                  class="font-size-15"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="auto">
                <v-btn class="custom--btn" block depressed  color="cyan accent-4" height="40" dark @click="createCampaign.showModal = true">Create Campaign</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>


        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="campaignsTable.headers"
          :items="filteredCampaigns"
          :page.sync="campaignsTable.page"
          :items-per-page="campaignsTable.itemsPerPage"
          hide-default-footer
          @page-count="campaignsTable.pageCount = $event"
          class="table--sm fill-space-first-cell show-sort-icons-always elevation-0 table--row-70 custom--border"
        >
          <template v-slot:item.type="{ item }">
            <v-chip
                :color="`${rotatorTypes[item.type] ? rotatorTypes[item.type].color : 'light-blue'} accent-2`"
                dark
                small
            >
              {{ rotatorTypes[item.type] ? rotatorTypes[item.type].text : 'N/A' }}
            </v-chip>
          </template>

          <template v-slot:item.name="{ item }">
            <h3 class="text-truncate font-weight-medium" style="max-width: 450px">{{item.name}}</h3>
            <v-flex align="center" class="grey--text text--darken-1">
              <template v-if="item.asin">
                <span>{{item.asin}}</span>
                <span class="mx-2">|</span>
              </template>

              <template v-if="item.type === 'walmartViaBrand' || item.type === 'walmartViaSeller' || item.type === 'walmartDirect'">Walmart</template>
              <template v-else>
                <span v-if="item.market && dataByMarketplaceId[item.market]">{{ dataByMarketplaceId[item.market].country }}</span>
              </template>

            </v-flex>
          </template>

          <template v-slot:item.statistics.day="{ item }">
            {{item.statistics.day.toLocaleString()}} / {{item.statistics.twoWeek.toLocaleString()}}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-row align="center" justify="center" dense>
              <v-col cols="auto">
                <v-btn  :to="'/rotator/statistics/' + item._id" class="custom--btn " color="cyan accent-4" outlined depressed small>Statistics</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn  :to="'/rotator/' + item._id" class="custom--btn " color="cyan accent-4" outlined depressed small>Edit</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn  class="custom--btn " color="cyan accent-4" outlined depressed small @click="handleDeleteClicked(item)">Delete</v-btn>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.key="{ item }">
            <RotatorFinalUrl :campaign="item" />
          </template>
        </v-data-table>

        <v-row class="mt-3 mb-0" align="center" v-show="campaignsTable.pageCount > 1">
          <v-col cols="12">
            <v-pagination
                v-model="campaignsTable.page"
                :length="campaignsTable.pageCount"
            ></v-pagination>
          </v-col>
        </v-row>

        <QrCodeModal />

        <v-dialog
            v-model="createCampaign.showModal"
            width="778"
        >
          <v-card class="">
            <v-card-title class="text-h6 px-7 py-6 grey lighten-4 ">
              <div class="position-relative width-100 ">
                <span class="d-block pr-6">New Campaign</span>
                <v-icon @click="createCampaign.showModal = false" small class="cursor-pointer absolute-right absolute-vertical-center position-absolute">mdi-close</v-icon>
              </div>
            </v-card-title>

            <v-form class="py-7" v-model="createCampaign.valid" ref="createCampaignForm" v-if="createCampaignTypeData" @submit.prevent="handleSubmitAddNewCampaign">

              <div class="px-7">
                <v-row>
                  <v-col cols="12"
                  >
                    <Label forInput="createCampaignType">Campaign Type:</Label>
                    <v-select
                        :items="rotatorTypesWithoutAlexa"
                        v-model="createCampaign.type"
                        persistent-placeholder
                        outlined
                        dense
                        flat
                        hide-details="auto"
                        id="createCampaignType"
                        :item-disabled="disableTypeOption"
                        class="font-size-15"
                        :error-messages="createCampaign.errors.type"
                    ></v-select>

                    <v-alert
                        dense
                        text
                        class="align-icon-center mt-3 mb-0 pa-5 custom-alert-bg--grey"
                    >
                      <div class="d-flex align-center">
                        <v-icon color="cyan accent-4" >mdi-information</v-icon>
                        <v-divider
                            vertical
                            class="mx-5"
                        ></v-divider>
                        <div class="text-body-2">
                          <p class="mb-0 grey--text text--darken-3" v-if="createCampaignTypeData.createText">{{createCampaignTypeData.createText}}</p>
                          <a href="#" class="text-decoration-none " v-if="createCampaignTypeData.linkExample">
                            {{createCampaignTypeData.linkExample}}
                          </a>
                        </div>
                      </div>
                    </v-alert>
                  </v-col>

                  <v-col cols="12" md="6"
                  >
                    <Label forInput="createCampaignName">Campaign Name:</Label>
                    <v-text-field
                        v-model.trim="createCampaign.name"
                        persistent-placeholder
                        placeholder="Enter Name"
                        outlined
                        dense
                        solo
                        flat
                        :rules="requiredRules"
                        class="font-size-15"
                        hide-details="auto"
                        id="createCampaignName"
                        :error-messages="createCampaign.errors.name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" v-if="createCampaignTypeData.showAsinFieldOnCreate"
                         md="6"
                  >
                    <Label forInput="createCampaignAsin">Campaign ASIN:</Label>
                    <v-text-field
                        v-model.trim="createCampaign.asin"
                        outlined
                        placeholder="Enter ASIN"
                        persistent-placeholder
                        dense
                        class="font-size-15"
                        flat
                        :rules="requiredRules.concat(asinRules)"
                        hide-details="auto"
                        id="createCampaignAsin"
                        :error-messages="createCampaign.errors.asin"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <Label forInput="createCampaignMarket">Campaign Marketplace:</Label>

                    <WalmartMarketplaceOption v-if="createCampaign.type === 'walmartViaBrand' || createCampaign.type === 'walmartViaSeller' || createCampaign.type === 'walmartDirect'" />

                    <v-select
                        v-else
                        :items="marketplaceOptions"
                        v-model="createCampaign.market"
                        :disabled="createCampaign.type === 'walmartViaBrand' || createCampaign.type === 'walmartViaSeller' || createCampaign.type === 'walmartDirect'"
                        persistent-placeholder
                        outlined
                        dense
                        flat
                        class="font-size-15"
                        hide-details="auto"
                        id="createCampaignMarket"
                        :item-disabled="disableMarketplaceOption"
                        :error-messages="createCampaign.errors.market"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" :md="createCampaignTypeData.showAsinFieldOnCreate || createCampaign.type === 'walmartDirect' ? '6' : '12'">
                    <Label forInput="createCampaignDomain-select">Custom Domain:</Label>
                    <v-select
                        :items="verifiedDomainsAndDefault"
                        v-model="createCampaign.customDomain"
                        persistent-placeholder
                        outlined
                        dense
                        flat
                        class="font-size-15"
                        hide-details="auto"
                        id="createCampaignDomain-select"
                        :error-messages="createCampaign.errors.customDomain"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" v-if="createCampaign.type === 'walmartDirect'"
                         md="6"
                  >
                    <Label forInput="createCampaignItemId">Campaign Item ID:</Label>
                    <v-text-field
                        v-model.trim="createCampaign.itemId"
                        outlined
                        placeholder="Enter Item ID"
                        persistent-placeholder
                        dense
                        class="font-size-15"
                        flat
                        :rules="requiredRules.concat(itemIdRules)"
                        hide-details="auto"
                        id="createCampaignItemId"
                        :error-messages="createCampaign.errors.itemId"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                        color="cyan accent-4 ma-auto" class="custom--btn" type="submit" dark block depressed height="40"
                    >
                      Create Campaign
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-card>
        </v-dialog>

        <ConfirmModal
          title="Delete Campaign"
          :show.sync="deleteCampaign.showModal"
          @onConfirm="onDeleteCampaign(deleteCampaign.campaignToDelete._id)"
          @onClose="deleteCampaign.showModal = false; deleteCampaign.campaignToDelete = null;"
        >
          <v-card-text class="pa-0" v-if="deleteCampaign.campaignToDelete">
            <p class="mb-0">
              <span class="text--primary font-weight-medium">Name:</span> {{deleteCampaign.campaignToDelete.name}}
            </p>
            <p class="mb-0" v-if="rotatorTypes[deleteCampaign.campaignToDelete.type]">
              <span class="text--primary font-weight-medium">Type:</span> {{rotatorTypes[deleteCampaign.campaignToDelete.type].text}}
            </p>
            <p class="mb-0" v-if="deleteCampaign.campaignToDelete.asin">
              <span class="text--primary font-weight-medium">ASIN:</span> {{deleteCampaign.campaignToDelete.asin}}
            </p>
          </v-card-text>
        </ConfirmModal>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { sortCampaigns, setCampaignUrl } from '../js/rotator';
import { mapGetters, mapState, mapMutations } from 'vuex';
import rotatorApi from '../api/rotator';
import Vue from 'vue';
import QRCode from '../components/QrCode';
import Label from '../components/Label';
import ConfirmModal from '../components/ConfirmModal';
import {showNotification, copyTextToClipboard, openLink} from "../js/helpers";
import { requiredRules, asinRules, itemIdRules } from '../js/validators';
import config from "../../config";
import WalmartMarketplaceOption from "../components/WalmartMarketplaceOption";
import RotatorFinalUrl from "../components/RotatorFinalUrl";
import QrCodeModal from "../components/QrCodeModal";
import {validationMessages} from "../helpers/contants";

const DEFAULT_MARKETPLACE = 'ATVPDKIKX0DER';

export default {
  name: 'Rotator',

  components: {
    WalmartMarketplaceOption,
    QRCode,
    Label,
    ConfirmModal,
    RotatorFinalUrl,
    QrCodeModal
  },

  watch: {
    'createCampaign.showModal': {
      handler: function() {
        if (!this.createCampaign.showModal) {
          this.clearAddCampaignModal();
        }
      }
    },
    'createCampaign.type': {
      handler: function() {
        if (this.createCampaign.type === 'walmartViaBrand' || this.createCampaign.type === 'walmartViaSeller' || this.createCampaign.type === 'walmartDirect') {
          this.createCampaign.market = DEFAULT_MARKETPLACE;
        }
      }
    }
  },

  computed: {
    ...mapState('rotator', {
      keywordDataByCampaign: 'keywordDataByCampaign'
    }),
    ...mapState(['dataByMarketplaceId']),
    ...mapGetters('rotator', {
      campaigns: 'getCampaigns',
      pixels: 'getPixels',
      marketplaceOptions: 'getMarketplacesOptions',
      rotatorTypes: 'getAvailableRotatorTypes'
    }),
    ...mapGetters('domains', ['verifiedDomainsAndDefault', 'defaultDomain', 'domainsDataById', 'defaultSystemDomain']),
    rotatorTypesWithoutAlexa() {
      return Object.values(this.rotatorTypes).filter(type => type.value !== 'alexaAmazonChoice' && type.value !== 'alexaBestSeller');
    },
    createCampaignTypeData() {
      const { rotatorTypes, createCampaign: { type } } = this;
      return rotatorTypes[type];
    },
    rotatorTypesForFilter() {
      return {
        'all': { value: 'all', icon: 'mdi-layers-outline', text: 'All Types'},
        ...this.rotatorTypes
      }
    },
    filteredCampaigns() {
      return this.campaignsForRender.sort(sortCampaigns).filter(campaign => {
        const {name = '', type} = campaign;

        return (this.filter.type === 'all' ? true : type === this.filter.type)
            && name.toLowerCase().includes(this.filter.search.toLowerCase());
      });
    },
    campaignsForRender() {
      const { campaigns = [], domainsDataById = {}, defaultDomain, defaultSystemDomain } = this;

      return campaigns
          .map(_campaign => setCampaignUrl(_campaign, { defaultDomain, domainsDataById, defaultSystemDomain }))
          .filter(_campaign => _campaign !== false);
    }
  },

  methods: {
    ...mapMutations(['setLoading']),
    clearAddCampaignModal() {
      this.createCampaign.valid = false;
      this.createCampaign.type = 'direct';
      this.createCampaign.name = '';
      this.createCampaign.asin = '';
      this.createCampaign.itemId = '';
      this.createCampaign.market = DEFAULT_MARKETPLACE;
      this.createCampaign.customDomain = this.defaultDomain;

      if (this.$refs.createCampaignForm) {
        this.$refs.createCampaignForm.resetValidation();
        this.resetCreateCampaignErrors();
      }
    },
    resetCreateCampaignErrors() {
      Object.keys(this.createCampaign.errors).forEach(error => this.createCampaign.errors[error] = '');
    },
    async handleSubmitAddNewCampaign(e) {
      this.resetCreateCampaignErrors();

      this.$nextTick(async function() {
        if (!this.$refs.createCampaignForm.validate() || !this.createCampaign.valid) { return }

        const { type, name, market, asin, customDomain, itemId } = this.createCampaign;

        const createdCampaign = {
          type,
          name,
          market,
          customDomain
        };

        if (this.createCampaignTypeData.showAsinFieldOnCreate && asin) {
          createdCampaign.asin = asin.toUpperCase();
        }

        if (type === 'walmartDirect' && itemId) {
          createdCampaign.data = JSON.stringify({ itemId });
        }

        this.setLoading(true);
        const { status, campaignId, errors = {} } = await rotatorApi.addCampaign.call(this.createCampaign, createdCampaign);

        if (!status) {
          this.setLoading(false);

          if (errors.hasOwnProperty('data')) {
            showNotification({ type: 'error', title: validationMessages.api });
          }

          return;
        }

        this.$nextTick(function() {
          this.createCampaign.showModal = false;

          showNotification({ title: 'Campaign Created!' });

          if (campaignId) {
            return this.$router.push(`/rotator/${campaignId}?validate=true`);
          }
        });
      });
    },
    disableMarketplaceOption(option) {
      const { type } = this.createCampaign;
      const { value } = option;

      return (type === 'alexaAmazonChoice' || type === 'alexaBestSeller') && (
          value === 'A2VIGQ35RCS4UG' || value === 'A2Q3Y263D00KWC' || value === 'ARBP9OOSHTCHU' || value === 'A1805IZSGTT6HS'
          || value === 'A1C3SOZRARQ6R3' || value === 'A17E79C6D8DWNP' || value === 'A2NODRKZP88ZB9' || value === 'A33AVAJ2PDY3EV'
          || value === 'A19VAU5U5O7RUS'
      );
    },
    disableTypeOption(option) {
      const { market } = this.createCampaign;
      const { value } = option;

      return (value === 'alexaAmazonChoice' || value === 'alexaBestSeller') && (
          market === 'A2VIGQ35RCS4UG' || market === 'A2Q3Y263D00KWC' || market === 'ARBP9OOSHTCHU' || market === 'A1805IZSGTT6HS'
          || market === 'A1C3SOZRARQ6R3' || market === 'A17E79C6D8DWNP' || market === 'A2NODRKZP88ZB9' || market === 'A33AVAJ2PDY3EV'
          || market === 'A19VAU5U5O7RUS'
      );
    },
    handleDeleteClicked(campaign) {
      this.deleteCampaign.campaignToDelete = campaign;

      this.$nextTick(function () {
        this.deleteCampaign.showModal = true;
      });
    },
    async onDeleteCampaign(campaignId) {
      this.deleteCampaign.showModal = false;

      this.setLoading(true);

      const { status } = await rotatorApi.deleteCampaign(campaignId);

      this.setLoading(false);

      if (!status) { return }

      showNotification({ title: 'Campaign Deleted!' });
      this.deleteCampaign.campaignToDelete = null;
    }
  },

  beforeCreate() {
    this.requiredRules = requiredRules;
    this.asinRules = asinRules;
    this.itemIdRules = itemIdRules;
    this.copyTextToClipboard = copyTextToClipboard;
    this.openLink = openLink;
  },

  async mounted() {
    this.setLoading(true);

    await rotatorApi.getCampaignsStatistics();
    await rotatorApi.getAllCampaigns();

    this.setLoading(false);

    this.$nextTick(function() {
      this.createCampaign.customDomain = this.defaultDomain;
    })
  },

  data: () => ({
    deleteCampaign: { showModal: false, campaignToDelete: null },
    createCampaign: {
      showModal: false, valid: false, type: 'direct', name: '', itemId: '',
      asin: '', market: DEFAULT_MARKETPLACE, customDomain: null,
      errors: { asin: '', type: '', name: '', customDomain: '', market: '', itemId: '' }
    },
    eventProvider: new Vue(),
    campaignsTable: {
      headers: [
        { text: 'Campaign', value: 'name', divider: true },
        { text: 'Type', value: 'type', align: 'center', divider: true, width: 255 },
        { text: 'Today / 14 days', value: 'statistics.day', align: 'center', divider: true, width: 170 },
        { text: 'URL', value: 'key', align: 'center', divider: true, width: 400 },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: 246 }
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 12,
    },
    filter: { search: '', type: 'all' }
  }),
}
</script>

<style lang="scss">

</style>